/*-------------------------------------------------------------------------------------------------
This file contains a collection of:
	HTML5 Broilerplate, Eric Meyer, and normalization cross-browser fixes
-------------------------------------------------------------------------------------------------*/
	*, *:before, *:after {-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;}
	html{-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;}
	html,body,div,span,applet,object,iframe,
	h1,h2,h3,h4,h5,h6,p,blockquote,pre,hr,
	a,abbr,address,cite,code,
	del,dfn,em,img,ins,kbd,q,s,samp,
	small,strong,sub,sup,tt,var,
	b,u,i,
	dl,dt,dd,ol,ul,li,
	fieldset,form,label,legend,
	table,caption,tbody,tfoot,thead,tr,th,td,
	article,aside,canvas,details,figcaption,figure,
	footer,header,hgroup,menu,nav,section,summary,
	time,mark,audio,video{
		margin:0;
		padding:0;
	}
	article,aside,details,figcaption,figure,footer,
	header,hgroup,menu,nav,section,summary,object,embed{
		display:block;
	}
	audio,canvas,video{display:inline-block;}
	audio,canvas,img,video {vertical-align: middle;}
	body{line-height:1.5;}
	h1,h2,h3,h4,h5,h6{font-size:100%;}
	table{
		border-collapse:collapse;
		border-spacing:0;
	}
	strong,b,mark{
		font-weight:bold;
		font-style:inherit;
	}
	em,i,cite,q,address,dfn,var{
		font-style:italic;
		font-weight:inherit;
	}
	abbr[title],dfn[title]{
		cursor:help;
		border-bottom:1px dotted;
	}
	ins{border-bottom:1px solid;}
	a{background-color: transparent;-webkit-text-decoration-skip: objects;}
	a:focus {outline: thin dotted;}
	a:active, a:hover {outline: 0;}
	a,u,ins{text-decoration:none;}
	abbr[title] {
		border-bottom: none;
		text-decoration: underline;
		text-decoration: underline dotted;
	}
	del,s{text-decoration:line-through;}
	pre,code,samp,kbd{font-family:monospace;}
	small{font-size:80%;}
	img{
		border:none;
		font-style:italic;
	}
	button,
	input,
	select,
	textarea{
		font-family: inherit;
		font-size: 100%;
		margin: 0;
		vertical-align: baseline;
		*vertical-align: middle;
	}
	textarea {overflow: auto;vertical-align: top;resize: vertical;}
	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"]{
		-webkit-appearance: button;
		cursor: pointer;
		*overflow: visible;
	}
	button[disabled],
	html input[disabled]{cursor: default;}
	label{cursor: pointer;}
	input[type="checkbox"],
	input[type="radio"]{padding: 0;}
	input[type="search"]{
		-webkit-appearance: textfield;
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
	}
	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-decoration{
		-webkit-appearance: none;
	}
	legend{border: 0;padding: 0;white-space: normal;}
	sub,
	sup{
		font-size: 75%;
		line-height: 0;
		position: relative;
	}
	sub{vertical-align:baseline;top:2px;}
	sup{vertical-align:top;top:3px;}
	hr{height: 0;}
	svg:not(:root){overflow: hidden;}

	button,
	html [type="button"],
	[type="reset"],
	[type="submit"] {
		-webkit-appearance: button;
	}
	button::-moz-focus-inner,
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
	button:-moz-focusring,
	[type="button"]:-moz-focusring,
	[type="reset"]:-moz-focusring,
	[type="submit"]:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
	legend {
		box-sizing: border-box;
		color: inherit;
		display: table;
		max-width: 100%;
		padding: 0;
		white-space: normal;
	}
	progress {display: inline-block;vertical-align: baseline;}
	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}
	[type="search"] {-webkit-appearance: textfield;outline-offset: -2px;}
	[type="search"]::-webkit-search-cancel-button,
	[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	::-webkit-file-upload-button {-webkit-appearance: button;font: inherit;}


	/* Misc Fixes for Random Plugins/Occurances -------------------------------------------------*/

		// HTML Templates(hide JS-manipulated markup from showing to user)
			template, #templates, .templates, [hidden]{display: none;}
